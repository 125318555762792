



























































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import VueHtml2pdf from "vue-html2pdf";
import { State, Getter } from "vuex-class";

@Component({
  components: {
    VueHtml2pdf
  }
})
export default class ProgramacionEventosProgramadosPdf extends Vue {
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.eventosProgramados)
  eventosProgramados!: any;
  @State(({ eventoState }) => eventoState.evento) evento!: any;
  // @State(({ eventoState }) => eventoState.evento.facturacion) facturacion!: any;
  @Getter obtenerTitulo: any;
  @Getter obtenerProgramacion: any;
  public name = "";
  protected programacion: any;
  protected titulo: any;

  created() {
    const { evento } = this.$route.params;
    this.titulo = this.condominio.nombre;
    this.programacion = this.obtenerProgramacion(evento);
    this.name = "Eventos Programados";
  }

  get definirRetraso(): any {
    const hoy = new Date().getTime();
    const fechaEstablecida = new Date(this.evento.fecha).getTime();
    const retraso = Math.floor((hoy - fechaEstablecida) / 86400000);
    if (retraso >= 0) {
      return retraso === 1 ? `${retraso} día` : `${retraso || "0"} días`;
    }
    return "0 días";
  }

  protected capitalize(text: string): string {
    const lower = text.toLowerCase();
    return `${text.charAt(0).toUpperCase()}${lower.slice(1)}`;
  }

  get fileName(): string {
    return `Informe de ${this.name}`;
  }
  public formatearFecha(fecha: string): string {
    if (fecha) {
      const fechaAString = `${fecha.replaceAll("/", "-")}T00:00:00`;
      return `${new Date(fechaAString).toLocaleDateString()}`;
    }
    return "Sin fecha";
  }

  public options = {
    margin: [5, 10, 5, 10],
    filename: `Informe de eventos programados`
  };
  private generatePdf(): void {
    (this.$refs.html2Pdf as Vue & { generatePdf: () => void }).generatePdf();
  }
}
