













































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import ProgramacionEventosProgramadosCalendario from "@/components/ProgramacionEventos/ProgramacionEventosProgramadosCalendario.vue";
import ProgramacionEventosProgramadosTabla from "@/components/ProgramacionEventos/ProgramacionEventosProgramadosTabla.vue";
import MantenimientoSinCondominio from "@/components/Mantenimiento/MantenimientoSinCondominio.vue";
import ProgramacionEventosProgramadosPdf from "@/components/ProgramacionEventos/ProgramacionEventosProgramadosPdf.vue";

import { State, Getter } from "vuex-class";

@Component({
  components: {
    ProgramacionEventosProgramadosCalendario,
    ProgramacionEventosProgramadosTabla,
    MantenimientoSinCondominio,
    ProgramacionEventosProgramadosPdf
  }
})
export default class EventosProgramados extends Vue {
  @State(({ appState }) => appState.condominios) condominios!: Array<any>;
  @State(({ appState }) => appState.programaciones) programaciones!: Array<any>;

  @Getter obtenerTitulo!: Function;

  get tieneCondominios(): boolean {
    return this.condominios.length > 0 ? true : false;
  }

  //NOMBRE DEL COMPONENTE ACTUAL A SER MOSTRADO EN EL <component>
  public componenteActual = "Calendario";

  get nombreDeAreaInstalacion(): string {
    const { evento } = this.$route.params;
    return this.obtenerTitulo(evento);
  }

  //ASIGNA AL COMPONENT :is EL COMPONENTE QUE SE DESAEA MOSTRAR
  get componenteSeleccionado(): string {
    return `ProgramacionEventosProgramados${this.componenteActual}`;
  }
}
